import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import img8 from "../Components/image2.jpg";
import img9 from "../Components/image4.jpg";
import img10 from "../Components/corporate-office-1j9334BkQD-dWB2OfpQ6-Q-WcjLl9m5SUiQmC8KBM54Ig.jpeg";
import img7 from "../Components/displayImage3.jpg";
import Footer from "./Footer";
import { FaBars, FaTimes } from "react-icons/fa";
import img1 from "../Components/dark theme logo (2).png";
import img2 from "../Components/Location Dekho (1).png";
import img3 from "../Components/dark theme logo (2).png";

function AboutUs() {
  const [shrinkingFilters, setShrinkingFilters] = useState(false);
  const navigating = useNavigate();
  const location = useLocation();

  const isAboutUsPage = location.pathname === "/AboutUs";

  return (
    <div>
      <div className="fixed top-0 z-50 w-full sm:flex">
        <div className="flex bg-white p-1 justify-between items-center">
          <img
            className="h-[35px] block sm:hidden rounded w-[125px]"
            src={img3}
            alt="Logo"
          />
          <button
            className={`sm:hidden rounded text-white bg-[#074173] p-1`}
            onClick={() => setShrinkingFilters(!shrinkingFilters)}
          >
            <FaBars size={15} />
          </button>
        </div>

        <div
          className={`${
            shrinkingFilters ? "block" : "hidden"
          } sm:flex sm:items-center sm:justify-between z-50 sm:mt-0 fixed top-0 bg-[#074173] w-full text-center p-2 sm:py-2 sm:px-5 text-white sm:text-[#074173] sm:bg-white shadow font-[600] sm:text-xl sm:w-full text-base`}
        >
          <div>
            <button
              onClick={() => setShrinkingFilters(!shrinkingFilters)}
              className="bg-white sm:hidden z-50 block rounded text-[#074173]"
            >
              <FaTimes size={24} />
            </button>
            <img
              className="h-[50px] hidden sm:block rounded w-[200px]"
              src={img1}
              alt="Logo"
            />
            <img
              className="h-[50px] block sm:hidden rounded ml-10 w-[230px]"
              src={img2}
              alt="Logo"
            />
          </div>
          <div className="sm:flex sm:items-center sm:w-[350px] md:w-[400px] sm:justify-between">
            <p onClick={() => navigating("/")}>Home</p>
            <p
              onClick={() => navigating("/AboutUs")}
              className={isAboutUsPage ? "border-b-2 border-[#074173]" : ""}
            >
              About Us
            </p>

            <p onClick={() => navigating("/ContactUs")}>Contact Us</p>
          </div>
        </div>
      </div>

      <div id="about-us" className="w-auto mt-20 px-10">
        <p className="text-[#074173] font-bold mb-2 text-xl sm:text-4xl">
          Who we are ?
        </p>
        <div className="">
          <p className="text-justify text-sm md:text-xl font-serif">
            Welcome to{" "}
            <span className="font-bold text-lg text-[#074173]">
              Location Dekho,
            </span>{" "}
            Your premier destination for discovering exceptional shooting and
            celebration locations awaits. Whether you're planning a pre-wedding
            photoshoot, filming a cinematic masterpiece, or creating an
            enthralling web series, our curated selection of diverse and
            picturesque locations is designed to inspire and fulfill your
            creative vision. For any event, large or small, we offer stunning
            venues tailored to your needs, including birthday parties, corporate
            events, engagement ceremonies, wedding celebrations, and more.
            Whether you envision an intimate gathering or a grand affair, our
            venues provide the perfect setting to make your occasion truly
            unforgettable. Explore our array of unique spaces, each chosen to
            enhance your experience with its charm and versatility. From serene
            natural landscapes to vibrant urban backdrops, every location is
            meticulously selected to offer both beauty and practicality. Partner
            with us to bring your ideas to life with our dedicated support and
            personalized service. Contact us today to begin planning your next
            event and discover how our exceptional venues can elevate your
            celebration or production to new heights of excellence.
          </p>
        </div>
      </div>

      <div className="mt-24">
        <p className=" text-[#074173]  px-10 text-xl font-bold sm:text-4xl">
          Why Choose Us ?
        </p>

        <div className="sm:flex items-center px-10 justify-center ">
          <p className="w-auto text-sm md:text-xl mt-5 md:mt-0 font-serif  text-justify">
            <p className="font-semibold text-[#074173]">
              Diverse and Detailed Venues:
            </p>{" "}
            Discover our array of stunning locations, ranging from natural
            landscapes to urban settings, meticulously designed with
            comprehensive amenities. Whether for a photo shoot, film production,
            or special celebration, our versatile venues provide the perfect
            backdrop. With options to suit various styles and sizes, we ensure a
            memorable experience tailored to your needs. Contact us today to
            explore how we can enhance your event or production with our
            customizable spaces and exceptional service.
          </p>
          <img
            src={img8}
            className="h-[300px] hidden md:block sm:ml-28 rounded-tl-3xl rounded-br-3xl hover:shadow-lg hover:shadow-white w-[400px]"
          />
        </div>

        <div className="sm:flex px-10 justify-center items-center">
          <img
            src={img7}
            className="h-[250px] hidden md:block rounded-tl-3xl rounded-br-3xl hover:shadow-lg hover:shadow-white w-[400px]"
          />
          <p className="w-auto text-sm md:text-xl md:ml-28 mt-5 md:mt-0 font-serif  text-justify">
            <p className="font-semibold text-[#074173]">Easy Contact:</p>{" "}
            Connect directly with us to secure your chosen location hassle-free.
            Our dedicated team is committed to ensuring your filming experience
            or celebration is smooth, successful, and tailored to your needs.
            Whether you're planning a film shoot, a special event, or a
            memorable gathering, we're here to provide personalized assistance
            every step of the way. Reach out today and let us help make your
            vision a reality with our expertise and unwavering support.
          </p>
        </div>

        <div className="sm:flex px-10 justify-center items-center">
          <p className="w-auto text-sm md:text-xl mt-5 md:mt-0 font-serif  text-justify">
            <p className="font-semibold text-[#074173]">
              Versatile Event Options:
            </p>{" "}
            Alongside venues for birthdays, weddings, pre-wedding shoots, and
            corporate events, we accommodate various occasions. Whether you need
            one venue or multiple locations, our team is dedicated to finding
            your ideal setting. From intimate gatherings to grand celebrations,
            we offer customizable options and exceptional service to ensure your
            event is memorable and seamless. Reach out today to explore how our
            diverse venues and personalized planning can bring your vision to
            life.
          </p>
          <img
            src={img9}
            className="h-[250px] hidden md:block rounded-tl-3xl md:ml-28 rounded-br-3xl hover:shadow-lg hover:shadow-white w-[400px]"
          />
        </div>

        <div className="sm:flex px-10  justify-center items-center">
          <img
            src={img10}
            className="h-[250px] hidden md:block rounded-tl-3xl rounded-br-3xl hover:shadow-lg hover:shadow-white w-[400px]"
          />
          <p className="w-auto md:ml-28 mt-5 md:mt-0 text-sm md:text-xl  font-serif text-justify">
            <p className="font-semibold text-[#074173]">Rental Services:</p>{" "}
            Secure our premier locations for shoots or events with easy booking
            and competitive rates. Our streamlined rental process ensures
            convenience and affordability. Whether you're planning a film shoot,
            corporate event, or private celebration, we offer flexible
            scheduling and comprehensive amenities. Contact us today to explore
            how we can meet your needs with top-notch locations and dedicated
            service.
          </p>
        </div>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
