import { Field, Form, Formik } from "formik";
import React from "react";
import FormikInput from "../Components/FormikInput";
import { db } from "../FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import * as Yup from "yup";

function Enquiry({ closing, locationId }) {
  const gettingCurrentDate = new Date().toISOString();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must have at least 2 characters"),

    phoneNo: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number should be at least 10 digits"),

    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),

    anyQuery: Yup.string()
      .min(6, "Query must be written")
      .required("Query is required"),
  });

  async function submittingQueries(values) {
    try {
      await addDoc(collection(db, "user-enquiry"), {
        name: values.name,
        email: values.email,
        phone: values.phoneNo,
        query: values.anyQuery,
        locationId: locationId,
        currentDate: gettingCurrentDate,
      });
      toast.success("Enquiry sent successfully!!!");
      closing(false);
    } catch (error) {
      toast.error("Failed to send enquiry!!!");
    }
  }

  return (
    <div className="bg-black flex flex-col justify-center items-center fixed inset-0 bg-opacity-70">
      <div className="bg-white shadow-xl rounded-md p-3">
        <div className="flex justify-between items-center">
          <p className="text-[#074173] ml-0 sm:ml-[140px] text-2xl font-bold">
            Enquiry Form
          </p>
          <button
            onClick={() => closing(false)}
            className="bg-red-500 ml-[20px] px-2 rounded text-white"
          >
            X
          </button>
        </div>
        <div className="mt-10 w-[300px] sm:w-[450px]">
          <Formik
            initialValues={{
              name: "",
              email: "",
              phoneNo: "",
              anyQuery: "",
            }}
            validationSchema={validationSchema}
            onSubmit={submittingQueries}
          >
            <Form>
              <div className="sm:flex mb-2 sm:justify-between">
                <FormikInput
                  name={"name"}
                  label={"Your Name"}
                  placeholder={"Enter your name"}
                />
                <FormikInput
                  name={"email"}
                  label={"Your Email"}
                  placeholder={"Enter your email"}
                />
              </div>
              <FormikInput
                name={"phoneNo"}
                label={"Your Phone No"}
                type={'number'}
                placeholder={"Enter your Phone No"}
              />
              <div className="flex flex-col mt-4">
                <label className="block text-[#074173] mb-2 font-bold">
                  Any Queries
                </label>
                <Field
                  name={"anyQuery"}
                  placeholder={"liked location? tell us..."}
                  className="h-20 border-2 py-1 px-3 rounded"
                />
              </div>
              <div className="sm:flex sm:justify-center mt-3">
                <button
                  type="submit"
                  className="bg-[#074173] p-2 rounded text-white px-10"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
