import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AdminDashboard from "./AdminSide/AdminDashboard";
import AddLocation from "./AdminSide/AddLocation";
import AddDetails from "./AdminSide/AddDetails";
import UserSection from "./UserSide/UserSection";
import UserExploreLocations from "./UserSide/UserExploreLocations";
import Login from "./AdminSide/AdminLogin";
import DetailedLocation from "./UserSide/DetailedLocation";
import EnquiryByUser from "./AdminSide/EnquiryByUser";
import AdminDetailedLocation from "./AdminSide/AdminDetailedDescription";
import PrivateRoute from "./Components/PrivateRoute";
import ContactUs from "./UserSide/ContactUs";
import AboutUs from "./UserSide/AboutUs";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<UserSection />} />
          <Route path="/AdminLogin" element={<Login />} />
          <Route
            path="/AdminDashboard"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddLocation"
            element={
              <PrivateRoute>
                <AddLocation />
              </PrivateRoute>
            }
          />
          <Route
            path="/AddDetails"
            element={
              <PrivateRoute>
                <AddDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="AdminDetailedDescription/:id"
            element={
              <PrivateRoute>
                <AdminDetailedLocation />
              </PrivateRoute>
            }
          />

          <Route
            path="/EnquiryByUser"
            element={
              <PrivateRoute>
                <EnquiryByUser />
              </PrivateRoute>
            }
          />
          <Route path="/DetailedLocation/:id" element={<DetailedLocation />} />
          <Route path="/AboutUs" element={<AboutUs   />} />
         
          <Route path="/ContactUs" element={<ContactUs   />} />
          <Route
            path="/UserExploreLocations"
            element={<UserExploreLocations />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
