import React from "react";
import AdminNavbar from "./Admin-navbar";
import SuitableFor from "./SuitableFor";
import LocationCategory from "./LocationCategory";

function AddDetails() {
  return (
    <div className="sm:flex  sm:flex-row">
      <AdminNavbar />
      <div className="flex-grow sm:ml-20 md:ml-40">
        <p className="text-center  text-[#074173] sm:mt-10 text-3xl font-bold">Add Details</p>

        <div className="flex flex-col justify-center  lg:flex-row">
          <LocationCategory />
          <SuitableFor />
        </div>
      </div>
    </div>
  );
}

export default AddDetails;
