import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const tobeAuthenticated = localStorage.getItem("isauthenticated");

  console.log(tobeAuthenticated);

  if (tobeAuthenticated == "true") {
    return children;
  } else {
    return <Navigate to={"/AdminLogin"} />;
  }
}

export default PrivateRoute;
