// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbXz_9M3NvJuhpOU0l3fNhdvHiGtzvIJU",
  authDomain: "shoot-location-finder.firebaseapp.com",
  projectId: "shoot-location-finder",
  storageBucket: "shoot-location-finder.appspot.com",
  messagingSenderId: "671314969797",
  appId: "1:671314969797:web:6ae14d5e66e8508248dcac",
  measurementId: "G-6L29GZ4YR2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
 
export {app, db};

