import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logout from "../Components/Logout";
import img1 from "../Components/Screenshot 2024-06-14 164834.png";

function AdminNavbar() {
  const location = useLocation();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <div>
      <button
        className={`bg-[#074173] mb-5 rounded text-white mt-2 ml-2 e p-2 md:hidden ${isNavbarOpen ? "hidden" : "block"}`}
        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
      >
        <FaBars size={24} />
      </button>

      <div
        className={`bg-[#074173] md:w-1/6 ${isNavbarOpen ? "block" : "hidden"} md:flex flex-col md:w-[230px] p-2 h-screen fixed w-60 text-white z-50`}
      >
        <button
          className={`bg-[#074173] rounded text-white p-2 md:hidden ${isNavbarOpen ? "block" : "hidden"} absolute top-2 right-2`}
          onClick={() => setIsNavbarOpen(false)}
        >
          <FaTimes size={24} />
        </button>

        <img className="h-[50px] rounded  w-[200px]" src={img1} alt="Logo" />

             <div className="ml-0 md:ml-5">

          <Link to="/AdminDashboard">
            <p
              className={`mt-10 font-semibold text-lg ${
                location.pathname === "/AdminDashboard"
                  ? "text-[#074173] bg-white p-1 mr-5 rounded"
                  : ""
              }`}
            >
              Locations
            </p>
          </Link>
          <Link to="/AddLocation">
            <p
              className={`mt-2 font-semibold text-lg ${
                location.pathname === "/AddLocation"
                  ? "text-[#074173] bg-white p-1 mr-5 rounded"
                  : ""
              }`}
            >
              Add Location
            </p>
          </Link>
          <Link to="/AddDetails">
            <p
              className={`mt-2 font-semibold text-lg ${
                location.pathname === "/AddDetails"
                  ? "text-[#074173] bg-white p-1 mr-5 rounded"
                  : ""
              }`}
            >
              Add Details
            </p>
          </Link>
          <Link to="/EnquiryByUser">
            <p
              className={`mt-2 font-semibold text-lg ${
                location.pathname === "/EnquiryByUser"
                  ? "text-[#074173] bg-white p-1 mr-5 rounded"
                  : ""
              }`}
            >
              User Enquiry
            </p>
          </Link>
        </div>
        <Logout/>
      </div>

      {isNavbarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
          onClick={() => setIsNavbarOpen(false)}
        ></div>
      )}
    </div>
  );
}

export default AdminNavbar;
