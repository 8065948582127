  import { ErrorMessage, Field } from "formik";
import React from "react";

function FormikInput({ label, type, name, placeholder, readOnly}) {
  return (
    <div>
      <label className="block text-[#074173] font-bold mb-2">{label}</label>
      <Field
        type={type}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        className="w-full text-[#074173] border-2 rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline active:border-[#074173]"
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm"
      />
    </div>
  );
}

export default FormikInput;
