import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Form, Formik } from "formik";
import FormikInput from "../Components/FormikInput";
import { MdDelete } from "react-icons/md";
import { BsFillPencilFill } from "react-icons/bs";
import { toast } from "react-toastify";
import * as Yup from "yup";

function LocationCategory() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const validationSchema = Yup.object().shape({
    category: Yup.string()
      .required("Category is required")
      .min(4, "Category must have at least 4 characters"),
  });

  function poppingUp(categoryId) {
    setCategoryToDelete(categoryId);
    setOpen(!open);
  }

  function openUpdatePopupLocationCategory(categoryId) {
    setUpdateId(updateId === categoryId ? null : categoryId);
  }

  async function updatingLocationCategory(categoryId, category) {
    try {
      await updateDoc(doc(db, "location_category", categoryId), {
        category: category,
      });
      toast.success("Category updated successfully");
      setUpdateId(null);
      gettingLocationCategory();
    } catch (error) {
      toast.error("Failed to update category");
    }
  }

  function submittingLocationCategory(locationCategory) {
    addDoc(collection(db, "location_category"), {
      category: locationCategory,
    })
      .then((res) => {
        toast.success(" Category added successfull!!!");
        console.log(res);
        gettingLocationCategory();
      })
      .catch((err) => {
        alert(err.message);
        console.log(err);
        toast.error("an error occured while added category!!!");
      });
  }

  async function gettingLocationCategory() {
    const querySnapshot = await getDocs(collection(db, "location_category"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(multipleArray);
  }

  useEffect(() => {
    gettingLocationCategory();
  }, []);

  function deletingCategory(categoryId) {
    try {
      deleteDoc(doc(db, "location_category", categoryId));
      toast.success("Category deleted successfully");
      setOpen(false);
      gettingLocationCategory();
    } catch (error) {
      toast.error("Failed to delete category");
    }
  }

  return (
    <div className="p-5">
      <div className=" flex items-center mt-0 md:mt-10">
        <Formik
          initialValues={{ category: "" }}
          onSubmit={(values, { setSubmitting }) => {
            submittingLocationCategory(values.category);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex items-center">
                <div className="mr-4 lg:ml-0 sm:ml-12 md:ml-52 flex items-center">
                  <p className="mr-1 text-[#074173] text-lg  font-[700]">
                    Location Category:
                  </p>
                  <FormikInput
                    name={"category"}
                    placeholder={"Enter Location Category"}
                  />
                </div>

                <button
                  type="submit"
                  className="bg-[#074173] mt-2 font-semibold px-4 rounded py-[8px] text-white"
                  disabled={isSubmitting}
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-7 flex justify-center">
        <div>
          <p className="text-xl text-center text-[#074173] font-[600]">
            List of Categories
          </p>
          <div className="overflow-auto h-96">
            <table className=" mt-5 border-2 ">
              <thead className="border-2 text-[#074173]">
                <tr>
                  <th className="border-2  px-4 py-2">Categories</th>
                  <th className="border-2  px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((locationCat) => (
                  <tr key={locationCat.id} className="border-2 ">
                    <td className="border-2  px-4 py-2">
                      <div className="text-center">
                        <p>{locationCat.category}</p>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex items-center justify-center">
                        <div className="bg-blue-100 rounded px-1">
                          <button
                            onClick={() => {
                              openUpdatePopupLocationCategory(locationCat.id);
                            }}
                            className="text-blue-500"
                          >
                            <BsFillPencilFill />
                          </button>
                          {updateId === locationCat.id && (
                            <div className="bg-black flex flex-col justify-center z-50 items-center fixed inset-0 bg-opacity-70">
                              <div className="bg-white p-3 rounded">
                                <div className="flex justify-between items-center">
                                  <p className="text-2xl font-bold text-[#074173]">
                                    Update
                                  </p>
                                  <button
                                    onClick={() => setUpdateId(null)}
                                    className="bg-red-500 text-white px-2 rounded"
                                  >
                                    X
                                  </button>
                                </div>
                                <Formik
                                  initialValues={{
                                    updatedLocationCategory:
                                      locationCat.category,
                                  }}
                                  onSubmit={(values, { setSubmitting }) => {
                                    updatingLocationCategory(
                                      locationCat.id,
                                      values.updatedLocationCategory
                                    );
                                    setSubmitting(false);
                                  }}
                                >
                                  {({ isSubmitting }) => (
                                    <Form>
                                      <div className="flex mt-5 flex-col justify-center">
                                        <FormikInput
                                          name={"updatedLocationCategory"}
                                          placeholder={
                                            "Updated Location Category"
                                          }
                                        />
                                        <button
                                          type="submit"
                                          className="bg-[#074173] text-white mt-3 px-2 py-1"
                                          disabled={isSubmitting}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="bg-red-100 rounded px-1 ml-2">
                          <button
                            onClick={() => poppingUp(locationCat.id)}
                            className="text-red-500"
                          >
                            <MdDelete />
                          </button>
                          {open && categoryToDelete == locationCat.id && (
                            <div className="bg-black flex flex-col justify-center z-50 items-center fixed inset-0 bg-cover bg-center bg-opacity-70">
                              <div className="bg-white w-[300px] p-3 rounded">
                                <p className="text-red-500 font-semibold text-lg">
                                  Are you sure you want to delete this category?
                                </p>
                                <div className="flex items-center mt-3 justify-between">
                                  <button
                                    onClick={() => setOpen(false)}
                                    className="text-white rounded px-2 bg-blue-500"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() =>
                                      deletingCategory(locationCat.id)
                                    }
                                    className="bg-red-500 px-2 rounded text-white"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationCategory;
