import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Form, Formik } from "formik";
import FormikInput from "../Components/FormikInput";
import { app } from "../FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import img1 from "../AdminSide/Untitled design.png";
import ResetPassword from "../Components/ResetPassword";
import img5 from "../Components/image1.jpeg";

function Login() {
  const navigate = useNavigate();

  const [openingClosing, setopeningClosing] = useState(false);

  function LoginUser(email, password) {
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/AdminDashboard");
        toast.success("Successfully Logged In!!!");
        localStorage.setItem("isauthenticated", true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error("Invalid Credentials");
        console.log(error);
      });
  }

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${img5})`,
          filter: "blur(8px)",
          zIndex: -1,
        }}
      ></div>
      <div className="border-2 rounded-xl shadow-xl bg-white p-8 z-10 md:flex md:items-center md:max-w-screen-lg">
        <div className="sm:flex hidden">
          <img src={img1} className="h-[500px] lg:w-[500px] md:w-[500px]" />
        </div>
        <div className="pr-12 ml-7">
          <p className="text-3xl text-[#074173] text-center mb-5 font-bold">
            Admin Login
          </p>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              LoginUser(values.email, values.password);
            }}
          >
            <Form>
              <FormikInput
                name="email"
                placeholder="Enter Email"
                label="Email"
                type="text"
              />
              <FormikInput
                name="password"
                placeholder="Enter Password"
                label="Password"
                type="password"
              />
              <button
                type="submit"
                className="bg-[#074173] text-white mt-4 py-1 rounded px-[87px]"
              >
                Login
              </button>
            </Form>
          </Formik>
          <button
            onClick={() => {
              setopeningClosing(!openingClosing);
            }}
            className="text-[#074173] block text-center mt-2 md:mt-4"
          >
            Forgot Password?
          </button>

          {openingClosing && <ResetPassword closing={setopeningClosing} />}
        </div>
      </div>
    </div>
  );
}

export default Login;
