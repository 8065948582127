import { ErrorMessage, Field } from "formik";
import React from "react";

function FormikSelect({ name, selectedItem, options, label, onChange }) {
  return (
    <div>
      <label className="block text-[#074173] font-bold mb-2">{label}</label>
      <Field
        name={name}
        onChange={onChange}
        
        as="select"
        className="appearance-none border-2 rounded w-full py-2 px-3 leading-tight  text-[#074173] font-semibold  focus:outline-none focus:shadow-outline "
      >
        <option className="font-semibold">{selectedItem}</option>
        {options &&
          options.map((e, index) => (
            <option key={index}>
                {e.category} {e.suitable_for}
            </option>
          ))}
      </Field>
      <ErrorMessage
        name={name}
        component={'div'}
        className="text-red-500"
      />
    </div>
  );
}

export default FormikSelect;
