import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { TbLogout2 } from "react-icons/tb";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Logout() {
    
    const navigate = useNavigate();

  function LoggingOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        toast.success("Logged-Out Successfully!!!");
        navigate('/')  
      })
      .catch(() => {
        toast.error("Logged-Out Failed!!!");
      });
  }

  return (
   <div className="mt-96">
   <div>
   <hr className="border-1 mb-2 border-white"/>
    <div className="flex  justify-center  items-center">
      <p className="text-lg mr-3">Admin</p>
      <button
        onClick={() => {
          LoggingOut();
        }}
        className="text-white text-3xl"
      >
        <TbLogout2 />
      </button>
    </div>
   </div>
   </div>
  );
}

export default Logout;
