import { Field, Form, Formik } from "formik";
import React from "react";
import FormikInput from "../Components/FormikInput";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import * as Yup from "yup";

function GeneralEnquiry() {
  const gettingCurrentDate = new Date().toISOString();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must have at least 2 characters"),

    phoneNo: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number should be at least 10 digits"),

    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),

    anyQuery: Yup.string()
      .min(6, "Query must be at least 6 characters")
      .required("Query is required"),
  });

  async function submittingGeneralQueries(values, { resetForm }) {
    console.log("Submitting values:", values);
    try {
      await addDoc(collection(db, "user-enquiry"), {
        name: values.name,
        email: values.email,
        phone: values.phoneNo,
        query: values.anyQuery,
        currentDate: gettingCurrentDate,
      });
      resetForm();
      toast.success("General Enquiry sent successfully!!!");
    } catch (error) {
      console.error("Error sending enquiry ", error);
      toast.error("Failed to send enquiry!!!");
    }
  }

  return (
    <div className="flex justify-center sm:block">
      <div className="sm:flex  sm:justify-center">
        <div className="md:w-[440px] sm:w-[380px] w-[350px] shadow-lg p-2">
          <p className="md:text-3xl text-2xl text-[#074173] mb-3 text-center font-bold">
            Get in Touch
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              phoneNo: "",
              anyQuery: "",
            }}
            validationSchema={validationSchema}
            onSubmit={submittingGeneralQueries}
          >
            <Form>
              <div className="md:flex mb-2 md:justify-between">
                <FormikInput
                  name={"name"}
                  label={"Your Name"}
                  placeholder={"Enter your name"}
                />
                <div className="md:mt-0 mt-2">
                  <FormikInput
                    name={"email"}
                    label={"Your Email"}
                    placeholder={"Enter your email"}
                  />
                </div>
              </div>

              <FormikInput
                name={"phoneNo"}
                label={"Your Phone No"}
                placeholder={"Enter your Phone No"}
              />

              <div className="flex flex-col mt-4">
                <label className="block text-[#074173] mb-2 font-bold">
                  Location Enquiry
                </label>
                <Field
                  name={"anyQuery"}
                  placeholder={"Express Your Queries...."}
                  className="h-20 border-2 py-1 px-3 rounded"
                />
              </div>

              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="bg-[#074173] p-2 rounded text-white px-10"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="md:w-[450px] w-[350px] shadow-lg p-2   md:mt-0 mt-5 sm:mt-0 ml-0 sm:ml-5 md:ml-20">
          <p className="md:text-3xl text-2xl text-[#074173]  mb-3 text-center font-bold">
            Contact Us
          </p>
          <div className="text-[#074173] font-semibold">
            <div className="flex ">
              <ImLocation2 className="mr-1 sm:text-lg text-base md:text-xl mt-1" />
              <p className="text-lg">
                Adani Shantigram, Ahmedabad, Gujarat, 382421.
              </p>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className=" mr-1" />
              <p className="text-base sm:text-base md:text-lg">9998973834</p>
            </div>
            <div className="flex mb-2 items-center">
              <p>
                {" "}
                <MdOutlineMail className="text-xl mr-1" />
              </p>
              <p className="text-base md:text-lg">locationdekho1@gmail.com</p>
            </div>

            <iframe
              className="md:h-60 sm:h-40 sm:w-[315px] w-[335px] md:w-[435px]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d160271.4013628783!2d72.42526922906714!3d23.037808803834984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9addb72f50bf%3A0x69b97a03027d26e6!2sShantigram!5e0!3m2!1sen!2sin!4v1718969675627!5m2!1sen!2sin"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralEnquiry;
