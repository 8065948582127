import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "./Admin-navbar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { FaRegEye } from "react-icons/fa";
import { MdGroupAdd, MdLocationOff } from "react-icons/md";
import Slider from "react-slick";

function EnquiryByUser() {
  const [enquiryDetails, setEnquiryDetails] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openingClosing, setopeningClosing] = useState(false);
  const [storingAdditionalImages, setstoringAdditionalImages] = useState(null);
  const sliderRef = useRef(null);

  const [settings] = useState({
    dots: true,
    infinite: false,
    slideToShow: 1,
    slideToScroll: 1,
    speed: 60,
    autoplay: false,
    accessibility: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.key === "ArrowLeft") {
          sliderRef.current.slickPrev();
        } else if (event.key === "ArrowRight") {
          sliderRef.current.slickNext();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  async function fetchEnquiryDetails() {
    const querySnapshot = await getDocs(collection(db, "user-enquiry"));
    const enquiryArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const sortedEnquiries = enquiryArray.sort((a, b) => {
      return new Date(b.currentDate) - new Date(a.currentDate);
    });

    setEnquiryDetails(sortedEnquiries);
  }

  async function fetchLocations() {
    const querySnapshot = await getDocs(collection(db, "adding_location"));
    const locationsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLocations(locationsArray);
  }

  useEffect(() => {
    fetchEnquiryDetails();
    fetchLocations();
  }, []);

  return (
    <div className="flex">
      <AdminNavbar />
      <div className="ml-4 md:ml-44 lg:ml-[280px]">
        <p className="mt-10 sm:ml-0 ml-10 text-3xl sm:text-3xl sm:text-center text-[#074173] font-bold">
           User Enquiry
        </p>

        <div className="sm:flex sm:justify-center sm:items-center">
          <div className=" mx-ml-0 md:ml-20 lg:ml-9 lg:w-auto md:w-[700px] sm:w-[640px] w-[260px]  block h-[560px] overflow-auto">
            <table className="mt-6 min-w-full border-collapse">
              <thead>
                <tr className="text-[#074173]">
                  <th className="border border-gray-300 px-2 py-1">Date</th>
                  <th className="border border-gray-300 px-2 py-1">Name</th>
                  <th className="border border-gray-300 px-2 py-1">Email</th>
                  <th className="border border-gray-300 px-2 py-1">Phone No</th>
                  <th className="border border-gray-300 px-2 py-1">
                    Query
                  </th>{" "}
                  <th className="border border-gray-300 px-2 py-1">
                    Location Name
                  </th>
                  <th className="border border-gray-300 px-2 py-1">
                    Location Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {enquiryDetails.map((enquiry) => {
                  const location = locations.find(
                    (loc) => loc.id === enquiry.locationId
                  );
                  return (
                    <tr key={enquiry.id} className="border-t">
                      <td className="border border-gray-300 px-3 py-2">
                        <p className="text-center">
                          {new Date(enquiry.currentDate).toLocaleDateString(
                            "en-US"
                          )}
                        </p>
                      </td>
                      <td className="border border-gray-300 px-3 py-1">
                        <p className="text-center">{enquiry.name}</p>
                      </td>
                      <td className="border border-gray-300 px-3 py-1">
                        {enquiry.email}
                      </td>
                      <td className="border border-gray-300 px-5 py-1">
                        <p className="text-center">{enquiry.phone}</p>
                      </td>
                      <td className="border border-gray-300 px-2 py-1">
                        <div className="w-[200px]">
                          <p className="text-justify">{enquiry.query}</p>
                        </div>
                      </td>
                      <td className="border border-gray-300 px-2 py-1">
                        <div className="flex items-center">
                          {locations
                            .filter((name) => name.id === enquiry.locationId)
                            .map((e) => e.location_name)[0] || (
                            <div className="flex text-red-500 items-center">
                              <p>No Location</p>
                              <MdLocationOff />
                            </div>
                          )}
                        </div>
                      </td>

                      <td className="border border-gray-300 px-2 py-1">
                        <div className="flex justify-center">
                          {location ? (
                            <button
                              onClick={() => {
                                setSelectedLocation(location);
                              }}
                              className="bg-[#074173] px-2 py-1 rounded text-white"
                            >
                              <FaRegEye />
                            </button>
                          ) : (
                            <div className="flex text-red-500 items-center">
                              <p>No Location</p>
                              <MdLocationOff />
                            </div>
                          )}
                          {selectedLocation &&
                            selectedLocation?.id === location?.id && (
                              <div className="bg-black flex flex-col justify-center items-center fixed inset-0 bg-opacity-70">
                                <div className="bg-white rounded overflow-auto h-[600px] p-3">
                                  <div className="flex items-center justify-between">
                                    <p className="text-[#074173] sm:ml-[210px] text-2xl font-bold">
                                      Location Details
                                    </p>
                                    <button
                                      onClick={() => {
                                        setSelectedLocation(false);
                                      }}
                                      className="bg-red-500 rounded text-white px-2"
                                    >
                                      x
                                    </button>
                                  </div>

                                  {location ? (
                                    <>
                                      <div className="">
                                        <p className="text-[#074173] text-2xl font-bold mt-3 mb-3 ">
                                          {location.location_name}
                                        </p>

                                        <img
                                          className="sm:h-[300px] h-60 w-[320px] rounded sm:w-[590px]"
                                          src={location.image}
                                        />
                                        {storingAdditionalImages && (
                                          <div className="bg-black flex flex-col md:justify-center items-center fixed inset-0 bg-opacity-70">
                                            <div className="md:mt-0 sm:w-[500px] mt-60 sm:mt-40 md:w-[820px] w-[320px] rounded sm:rounded-xl   sm:p-3">
                                              <button
                                                onClick={() => {
                                                  setstoringAdditionalImages(
                                                    false
                                                  );
                                                }}
                                                className="bg-red-500 ml-[295px] sm:ml-[450px]  md:ml-[772px] px-2  text-white"
                                              >
                                                X
                                              </button>
                                              <Slider
                                                ref={sliderRef}
                                                {...settings}
                                              >
                                                {location.additionalImages.map(
                                                  (addImg, index) => (
                                                    <img
                                                      key={index}
                                                      src={addImg}
                                                      className="md:h-[450px] sm:h-[350px] h-52"
                                                    />
                                                  )
                                                )}
                                              </Slider>
                                            </div>
                                          </div>
                                        )}

                                        <p className="text-[#074173] mt-3 text-center mb-2 font-bold">
                                          more photos
                                        </p>
                                        <div className="sm:grid grid gap-5 grid-cols-2 w-[260px] sm:ml-0 ml-9 sm:w-auto sm:grid-cols-4">
                                          {location.additionalImages.map(
                                            (imageUrl) => (
                                              <img
                                                src={imageUrl}
                                                onClick={() => {
                                                  setstoringAdditionalImages(
                                                    imageUrl
                                                  );
                                                }}
                                                className="h-[80px] rounded w-[100px]"
                                              />
                                            )
                                          )}
                                        </div>
                                      </div>

                                      <div className="mt-14">
                                        <div className="sm:flex">
                                          <div className="">
                                            <p className="text-[#074173] text-xl font-bold">
                                              Location Details
                                            </p>
                                            <div className="flex mb-2">
                                              <div className="">
                                                <p className="text-[#074173] font-semibold">
                                                  Category:
                                                </p>
                                                <div className="ml-1">
                                                  {location.locationCategory.map(
                                                    (cat, index) => (
                                                      <>
                                                        <li key={index}>
                                                          {cat}
                                                        </li>
                                                      </>
                                                    )
                                                  )}
                                                </div>
                                              </div>

                                              <div className="ml-3">
                                                <p className="text-[#074173] font-semibold">
                                                  Suitable For:
                                                </p>
                                                <div className="ml-1">
                                                  {location.suitableFor.map(
                                                    (suit, index) => (
                                                      <>
                                                        <li key={index}>
                                                          {suit}
                                                        </li>
                                                      </>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                State:
                                              </p>
                                              <p className="ml-1">
                                                {location.state}
                                              </p>
                                            </div>

                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                City:
                                              </p>
                                              <p className="ml-1">
                                                {location.city}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="sm:mt-0 mt-3 sm:ml-[50px]">
                                            <p className="text-[#074173]  text-xl font-bold">
                                              Additional Details
                                            </p>
                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                Staying Facility:
                                              </p>
                                              <p className="ml-1">
                                                {location.staying_facilities}
                                              </p>
                                            </div>

                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                Remarks:
                                              </p>
                                              <p className="ml-1">
                                                {location.location_remark}
                                              </p>
                                            </div>
                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                Terms & Condition:
                                              </p>
                                              <p className="ml-1">
                                                {location.termsAndCondition}
                                              </p>
                                            </div>

                                            <div className="flex">
                                              <p className="text-[#074173] font-semibold">
                                                Crowd Allowed:
                                              </p>
                                              <p className="ml-1 flex items-center">
                                                {location.crowd_allowed}{" "}
                                                <MdGroupAdd className="ml-1 text-lg" />
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mt-8">
                                          <p className="text-[#074173] mb-1  text-xl font-bold">
                                            Public Facilities
                                          </p>

                                          <div className="sm:flex">
                                            <div>
                                              <p className="text-[#074173] text-lg font-bold">
                                                Nearby Airport
                                              </p>
                                              <div>
                                                <div className="flex">
                                                  <p className="text-[#074173] font-semibold">
                                                    Name:
                                                  </p>
                                                  <p className="ml-1">
                                                    {location.airport_name}
                                                  </p>
                                                </div>

                                                <div className="flex">
                                                  <p className="text-[#074173] font-semibold">
                                                    Km:
                                                  </p>
                                                  <p className="ml-1">
                                                    {location.airport_km} km
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="ml-0 sm:ml-[100px]">
                                              <p className="text-[#074173] text-lg font-bold">
                                                Nearby Railway Station
                                              </p>
                                              <div>
                                                <div className="flex">
                                                  <p className="text-[#074173] font-semibold">
                                                    Name:
                                                  </p>
                                                  <p className="ml-1">
                                                    {location.station_name}
                                                  </p>
                                                </div>

                                                <div className="flex">
                                                  <p className="text-[#074173] font-semibold">
                                                    Km:
                                                  </p>
                                                  <p className="ml-1">
                                                    {location.station_km} km
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="mt-2">
                                            <p className="text-[#074173]  text-lg font-bold">
                                              Nearby Bus Stop
                                            </p>
                                            <div>
                                              <div className="flex">
                                                <p className="text-[#074173] font-semibold">
                                                  Name:
                                                </p>
                                                <p className="ml-1">
                                                  {location.stop_name}
                                                </p>
                                              </div>

                                              <div className="flex">
                                                <p className="text-[#074173]   font-semibold">
                                                  Km:
                                                </p>
                                                <p className="ml-1">
                                                  {location.stop_km} km
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-[300px] sm:w-[500px] mt-8">
                                            <p className="text-[#074173] text-lg font-bold">
                                              Description:
                                            </p>
                                            <p className="text-justify">
                                              {location.location_desciption}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div>
                                      {alert(
                                        "the location has been delete or not available"
                                      )}
                                      {console.log("location not available")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryByUser;
