import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "./Admin-navbar";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { MdGroupAdd } from "react-icons/md";
import { Form, Formik } from "formik";
import FormikSelect from "../Components/FormikSelect";
import CityStateFiltering from "./CityStateFiltering";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { BsFillPencilFill } from "react-icons/bs";
import LocationUpdate from "./LocationUpdate";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function AdminDashboard() {
  const [locationDetails, setLocationDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [openingClosing, setopeningClosing] = useState(false);
  const [data, setData] = useState([]);
  const [filteringByCategory, setFilteringByCategory] = useState(null);
  const [suitable, setsuitable] = useState([]);
  const [filteringBySuitableFor, setfilteringBySuitableFor] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [openingUpdatePopUp, setopeningUpdatePopUp] = useState(false);
  const [storingByDefault, setstoringByDefault] = useState([]);
  const [shrinkingFilters, setshrinkingFilters] = useState(false);
  const [filteringByLocationType, setFilteringByLocationType] = useState(null);
  const [locationToDelete, setlocationToDelete] = useState(null);
  const [openingImages, setopeningImages] = useState(false);
  const [openingMoreImg, setOpeningMoreImg] = useState(null);
  const sliderRef = useRef(null);
  const [settings] = useState({
    dots: true,
    infinite: false,
    slideToShow: 1,
    slideToScroll: 1,
    speed: 60,
    autoplay: false,
    accessibility: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.key === "ArrowLeft") {
          sliderRef.current.slickPrev();
        } else if (event.key === "ArrowRight") {
          sliderRef.current.slickNext();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function poppingUp(locationId) {
    setlocationToDelete(locationId);
    setopeningClosing(!openingClosing);
  }

  async function getLocationDetails() {
    const querySnapshot = await getDocs(collection(db, "adding_location"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  console.log(multipleArray)
    let filteredArray = multipleArray;
    setstoringByDefault(filteredArray);

    if (filteringByCategory !== null) {
      filteredArray = filteredArray.filter((location) => {
        return (
          Array.isArray(location.locationCategory) &&
          location.locationCategory.includes(filteringByCategory)
        );
      });
    }
    if (filteringBySuitableFor !== null) {
      filteredArray = filteredArray.filter((location) => {
        return (
          Array.isArray(location.suitableFor) &&
          location.suitableFor.includes(filteringBySuitableFor)
        );
      });
    }

    if (selectedState !== null) {
      filteredArray = filteredArray.filter(
        (location) => selectedState === location.state
      );
    }

    if (selectedDistrict !== null) {
      filteredArray = filteredArray.filter(
        (location) => selectedDistrict === location.district
      );
    }
    if (filteringByLocationType !== null) {
      filteredArray = filteredArray.filter(
        (location) => location.location_type === filteringByLocationType
      );
    }

    setLocationDetails(filteredArray);
  }

  async function deletingLocation(locationId) {
    try {
      await deleteDoc(doc(db, "adding_location", locationId));
      toast.success("location deleted successfully");
      setOpen(false);
      getLocationDetails();
      setopeningClosing(false);
    } catch (error) {
      toast.error("Failed to delete location");
    }
  }

  function openUpdatePopupLocationUpdate(locationId) {
    setopeningUpdatePopUp(
      openingUpdatePopUp === locationId ? null : locationId
    );
  }

  async function gettingLocationCategory() {
    const querySnapshot = await getDocs(collection(db, "location_category"));
    const category = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(category);
  }

  async function gettingSuitableFor() {
    const querySnapshot = await getDocs(collection(db, "suitable_for"));
    const suitable = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setsuitable(suitable);
  }

  useEffect(() => {
    getLocationDetails();
  }, [
    filteringBySuitableFor,
    filteringByCategory,
    selectedState,
    selectedDistrict,
    filteringByLocationType,
  ]);

  useEffect(() => {
    gettingLocationCategory();
    gettingSuitableFor();
  }, []);

  return (
    <div className="sm:flex">
      <AdminNavbar />
      <div className="md:ml-64 lg:ml-72  sm:mt-5">
        <div className="flex ml-3 sm:ml-0  items-center">
          <button
            className={`bg-[#074173] ${shrinkingFilters} rounded text-white text-semibold sm:hidden px-2 py-1`}
            onClick={() => setshrinkingFilters(!shrinkingFilters)}
          >
            Filter
          </button>
          <p className=" ml-3 sm:ml-[230px] md:ml-[170px] lg:ml-[370px] text-3xl sm:text-4xl md:text-4xl text-[#074173] font-bold">
            Explore Locations
          </p>
        </div>
        <div>
          <div
            className={`mt-6 sm:flex ${shrinkingFilters ? "block" : "hidden"} `}
          >
            <div className="lg:flex">
              <div className=" sm:flex sm:ml-20 ml-24 md:ml-20 lg:ml-0 xl:ml-2">
                <div className="flex  border-2 w-[177px] ml-2  lg:ml-0 sm:w-auto rounded-xl border-[#074173] items-center mt-2 mr-7">
                  <button
                    className={`rounded-tl-xl rounded-bl-xl  font-semibold px-2 py-[7px] text-[#074173] ${
                      filteringByLocationType === null
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setLocationDetails(storingByDefault);
                      setFilteringByCategory(null);
                      setfilteringBySuitableFor(null);
                      setSelectedState(null);
                      setSelectedDistrict(null);
                      setFilteringByLocationType(null);
                    }}
                  >
                    All
                  </button>
                  <button
                    className={`font-semibold px-2 py-[7px] text-[#074173] ${
                      filteringByLocationType === "private"
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => setFilteringByLocationType("private")}
                  >
                    Private
                  </button>
                  <button
                    className={` text-[#074173]  font-semibold rounded-tr-xl rounded-br-xl px-2 py-[7px] ml-3 ${
                      filteringByLocationType === "public"
                        ? "bg-[#074173] text-white"
                        : ""
                    }`}
                    onClick={() => setFilteringByLocationType("public")}
                  >
                    Public
                  </button>
                </div>
                <Formik>
                  <div className="sm:w-full  w-[200px]">
                    <Form>
                      <div className="sm:flex  w-auto sm:justify-between">
                        <div>
                          <FormikSelect
                            name={"suitableFor"}
                            selectedItem={"select suitable for"}
                            options={suitable}
                            onChange={(e) => {
                              setfilteringBySuitableFor(e.target.value);
                            }}
                          />
                        </div>

                        <div className="sm:ml-3 ml-0">
                          <FormikSelect
                            name={"category_name"}
                            selectedItem={"select category"}
                            options={data}
                            onChange={(e) => {
                              setFilteringByCategory(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </Formik>
              </div>

              <div className="sm:flex md:ml-[90px] lg:ml-0  lg:w-auto ml-24 sm:ml-20  sm:items-center">
                <div>
                  <CityStateFiltering
                    onStateChange={setSelectedState}
                    onDistrictChange={setSelectedDistrict}
                  />
                </div>
                <div className="mt-[6px] sm:0  sm:ml-3 ml-14">
                  <button
                    onClick={() => {
                      setLocationDetails(storingByDefault);
                      setFilteringByCategory(null);
                      setfilteringBySuitableFor(null);
                      setSelectedState(null);
                      setSelectedDistrict(null);
                      setFilteringByLocationType(null);
                    }}
                    className="bg-[#074173] rounded px-2 py-[7px] text-white"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        <div className="  sm:ml-0 grid lg:grid-cols-3  overflow-auto h-[550px] mt-3 sm:grid-cols-2   md:gap-5 grid-cols-1">
          {locationDetails.map((location) => (
            <div
              key={location.id}
              className="border-2 m-2 p-2 rounded shadow-xl bg-white"
            >
              {openingMoreImg && openingImages && (
                <div className="bg-black z-50 flex flex-col md:justify-center items-center fixed inset-0  bg-opacity-70">
                  <div className=" md:mt-0 sm:w-[500px] mt-60 sm:mt-40 md:w-[820px] w-[320px]  rounded sm:rounded-xl   sm:p-3">
                    <button
                      onClick={() => {
                        setOpeningMoreImg(null);
                        setopeningImages(false);
                      }}
                      className="bg-red-500 ml-[295px] sm:ml-[450px]  md:ml-[772px] px-2  text-white"
                    >
                      X
                    </button>
                    <Slider ref={sliderRef} {...settings}>
                      <img
                        src={openingMoreImg.image}
                        className="md:h-[450px] sm:h-[350px] h-52"
                      />
                      {openingMoreImg.additionalImages.map((addImg, index) => (
                        <img
                          key={index}
                          src={addImg}
                          className="md:h-[450px] sm:h-[350px] h-52"
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
              <img
                onClick={() => {
                  setOpeningMoreImg(location);
                  setopeningImages(!openingImages);
                }}
                src={location.image}
                className="h-48 w-full object-cover rounded"
                alt={location.location_name}
              />

              <div className="flex items-center justify-between mt-2">
                <p className="text-lg text-[#074173] font-bold">
                  {location.location_name}
                </p>
                <div className="flex px-5 sm:px-3 md:px-5 items-center">
                  <button
                    onClick={() => {
                      openUpdatePopupLocationUpdate(location.id);
                    }}
                  >
                    <BsFillPencilFill className="text-blue-500 text-lg" />
                  </button>

                  {openingUpdatePopUp === location.id && (
                    <LocationUpdate
                      locationDetails={locationDetails}
                      setopeningUpdatePopUp={setopeningUpdatePopUp}
                      locationId={location.id}
                      gettingLocationCategory={gettingLocationCategory}
                      getLocationDetails={getLocationDetails}
                    />
                  )}

                  <button
                    onClick={() => {
                      poppingUp(location.id);
                    }}
                  >
                    <MdDelete className="text-red-500 text-2xl" />
                  </button>

                  {openingClosing && locationToDelete == location.id && (
                    <div className="bg-black flex flex-col justify-center items-center fixed inset-0 bg-cover bg-center z-50 bg-opacity-50">
                      <div className="bg-white p-2 w-[300px] rounded">
                        <p className="text-red-500 font-semibold text-lg">
                          Are you sure want to delete this location?
                        </p>
                        <div className="flex items-center mt-3 justify-between">
                          <button
                            onClick={() => setopeningClosing(false)}
                            className="text-white rounded px-2 bg-blue-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => deletingLocation(location.id)}
                            className="bg-red-500 px-2 rounded text-white"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Link
                  to={`/AdminDetailedDescription/${location.id}`}
                  key={location.id}
                >
                  {" "}
                  <button className="text-white px-2 rounded py-1 bg-[#074173]">
                    View More...
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
