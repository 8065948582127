import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import FormikInput from "../Components/FormikInput";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { BsFillPencilFill } from "react-icons/bs";
import * as Yup from "yup";


function SuitableFor() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [suitableToDelete, setSuitableToDelete] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const validationSchema = Yup.object().shape({
    suitableFor: Yup.string()
      .required("suitable for is required")
      .min(4, "suitable for must have at least 4 characters"),
  });

  function poppingUp(categoryId) {
    setSuitableToDelete(categoryId);
    setOpen(!open);
  }

  function openUpdatePopupSuitableFor(suitableId) {
    setUpdateId(updateId === suitableId ? null : suitableId);
  }

  async function updatingSuitableFor(suitableId, suitableFor) {
    try {
      await updateDoc(doc(db, "suitable_for", suitableId), {
        suitable_for: suitableFor,
      });
      toast.success("Category updated successfully");
      setUpdateId(null);
      gettingSuitableFor();
    } catch (error) {
      toast.error("Failed to update category");
    }
  }

  async function deletingSuitablefor(suitableId) {
    try {
      await deleteDoc(doc(db, "suitable_for", suitableId));
      toast.success("Category deleted successfully");
      setOpen(false);
      gettingSuitableFor();
    } catch (error) {
      console.error("Error deleting category ", error);
      toast.error("Failed to delete category");
    }
  }

  function submittingSuitableFor(suitableFor) {
    addDoc(collection(db, "suitable_for"), {
      suitable_for: suitableFor,
    })
      .then(() => {
        toast.success("Added Successfully!!!");
        gettingSuitableFor();
      })
      .catch((err) => {
        toast.error("Failed to add!!!");
      });
  }

  async function gettingSuitableFor() {
    const querySnapshot = await getDocs(collection(db, "suitable_for"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(multipleArray);
  }

  useEffect(() => {
    gettingSuitableFor();
  }, []);

  return (
    <div className="p-5">
      <div>
        <div className="sm:mt-10 mt-0  ml-0 sm:ml-20 md:ml-52 lg:ml-20">
          <Formik
            initialValues={{ suitableFor: "" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              submittingSuitableFor(values.suitableFor);
              resetForm();
              setSubmitting(false);
            }}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex items-center">
                  <div className="mr-4 flex items-center">
                    <p className="mr-1 text-[#074173] text-lg font-[700]">
                      Suitable For:
                    </p>
                    <FormikInput
                      name={"suitableFor"}
                      placeholder={"Suitable For"}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-[#074173] font-semibold mt-2 px-4 rounded py-[8px] text-white"
                    disabled={isSubmitting}
                  >
                    Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="mt-7">
          <p className="text-xl text-[#074173] text-center font-[600]">List of Types</p>
          <div className="flex overflow-auto h-96 justify-center">
            <table className="border-2 mt-5">
              <thead className="border-2 text-[#074173]">
                <tr>
                  <th className="border-2 px-4 py-2">Categories</th>
                  <th className="border-2 px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((suitable) => (
                  <tr className="border-2" key={suitable.id}>
                    <td className="border-2 px-4 py-1">
                      <div className="text-center">
                        <p>{suitable.suitable_for}</p>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex items-center justify-center">
                        <div className="bg-blue-100 rounded px-1">
                          <button
                            onClick={() =>
                              openUpdatePopupSuitableFor(suitable.id)
                            }
                            className="text-blue-500"
                          >
                            <BsFillPencilFill />
                          </button>
                          {updateId === suitable.id && (
                            <div className="bg-black flex flex-col justify-center items-center fixed inset-0 z-50 bg-opacity-70">
                              <div className="bg-white p-3 rounded">
                                <div className="flex justify-between items-center">
                                  <p className="text-2xl font-bold text-[#074173]">
                                    Update
                                  </p>
                                  <button
                                    onClick={() => setUpdateId(null)}
                                    className="bg-red-500 text-white px-2 rounded"
                                  >
                                    X
                                  </button>
                                </div>
                                <Formik
                                  initialValues={{
                                    updatedSuitableFor: suitable.suitable_for,
                                  }}
                                  onSubmit={(values, { setSubmitting }) => {
                                    updatingSuitableFor(
                                      suitable.id,
                                      values.updatedSuitableFor
                                    );
                                    setSubmitting(false);
                                  }}
                                >
                                  {({ isSubmitting }) => (
                                    <Form>
                                      <div className="flex mt-5 flex-col justify-center">
                                        <FormikInput
                                          name={"updatedSuitableFor"}
                                          placeholder={"Updated Suitable For"}
                                        />
                                        <button
                                          type="submit"
                                          className="bg-[#074173] text-white mt-3 px-2 py-1"
                                          disabled={isSubmitting}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="bg-red-100 rounded px-1 ml-2">
                          <button
                            onClick={() => poppingUp(suitable.id)}
                            className="text-red-500"
                          >
                            <MdDelete />
                          </button>
                          {open && suitableToDelete === suitable.id && (
                            <div className="bg-black flex flex-col justify-center items-center z-50 fixed inset-0 bg-opacity-70">
                              <div className="bg-white w-[300px] p-2 rounded">
                                <p className="text-red-500 font-semibold text-lg">
                                  Are you sure you want to delete this?
                                </p>
                                <div className="flex items-center mt-3 justify-between">
                                  <button
                                    onClick={() => setOpen(false)}
                                    className="text-white rounded px-2 bg-blue-500"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() =>
                                      deletingSuitablefor(suitable.id)
                                    }
                                    className="bg-red-500 px-2 rounded text-white"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuitableFor;
