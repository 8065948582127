import React, { useEffect, useRef, useState } from "react";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { MdGroupAdd } from "react-icons/md";
import Footer from "../UserSide/Footer";
import Enquiry from "./Enquiry";
import { FaPhoneFlip } from "react-icons/fa6";
import { SiWhatsapp } from "react-icons/si";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import img1 from "../Components/dark theme logo (2).png";
import img2 from "../Components/Location Dekho (1).png";
import img3 from "../Components/dark theme logo (2).png";



function DetailedLocation() {
  const { id } = useParams();
  const [locationDetails, setLocationDetails] = useState(null);
  const [openingClosing, setOpeningClosing] = useState(false);
  const [openingMoreImg, setOpeningMoreImg] = useState(null);
  const [activeSection, setActiveSection] = useState("description");
  const [shrinkingFilters, setshrinkingFilters] = useState(false);
  const sliderRef = useRef(null);

  const [settings] = useState({
    dots: true,
    infinite: false,
    slideToShow: 1,
    slideToScroll: 1,
    speed: 60,
    autoplay: false,
    accessibility: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.key === "ArrowLeft") {
          sliderRef.current.slickPrev();
        } else if (event.key === "ArrowRight") {
          sliderRef.current.slickNext();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function poppingUp() {
    setOpeningClosing(!openingClosing);
  }

  const navigate = useNavigate();

  async function getLocationDetails() {
    const querySnapshot = await getDocs(collection(db, "adding_location"));
    const multipleArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const selectedLocation = multipleArray.find(
      (location) => location.id === id
    );
    setLocationDetails(selectedLocation);
    console.log(multipleArray);
  }

  useEffect(() => {
    getLocationDetails();
  }, []);

  return (
    <div
      className="h-40 sm:mt-10 w-full object-cover bg-center bg-cover"
      style={{
        backgroundImage: `url(${locationDetails?.image})`,
      }}
    >
      <div className="w-full sm:flex">
        <div className="flex items-center bg-white p-1 justify-between">
          <img
            className="h-[35px] block sm:hidden m rounded  w-[125px]"
            src={img3}
          />
          <button
            className={` ${shrinkingFilters} rounded mr-1 bg-[#074173] text-white sm:hidden  p-1`}
            onClick={() => setshrinkingFilters(!shrinkingFilters)}
          >
            <FaBars size={15} />
          </button>
        </div>

        <div
          className={`${
            shrinkingFilters ? "block" : "hidden"
          } sm:flex sm:items-center  sm:justify-between  sm:mt-0 fixed top-0 bg-[#074173]  w-full p-2 sm:py-2  sm:px-5 text-white sm:text-[#074173] sm:bg-white mr-[20px] shadow font-[600]  sm:text-xl sm:w-full text-base `}
        >
          <div>
            <button
              onClick={() => setshrinkingFilters(!shrinkingFilters)}
              className="bg-white sm:hidden block rounded text-[#074173]"
            >
              <FaTimes size={24} />
            </button>
            <img
              className="h-[50px] hidden sm:block rounded  w-[200px]"
              src={img1}
            />
            <img
              className="h-[50px] block sm:hidden ml-10 rounded  w-[230px]"
              src={img2}
            />
          </div>
          <div className="sm:flex sm:items-center sm:w-[350px] md:w-[400px] text-center sm:justify-between">
            <Link to={"/"}>
              <p onClick={() => navigate("/")}>Home</p>
            </Link>
            <Link smooth={true} duration={700}>
              <p onClick={() => navigate("/AboutUs")}>About Us</p>
            </Link>


              <p onClick={()=>{
                navigate('/ContactUs')
              }}>Contact Us</p>
          </div>
        </div>
      </div>

      <div
        className=" italic flex
       font-[650]  items-center ml-10 mt-10 sm:mt-20 md:mt-16 sm:ml-[200px]"
      >
        <p className="text-white text-3xl sm:text-5xl md:text-7xl mr-4">
          Reel Life,
        </p>
        <p className="text-white text-3xl sm:text-5xl md:text-7xl">
          Real Places.
        </p>
      </div>

      <div className=" mb-20">
        <button
          onClick={() => {
            navigate("/");
          }}
          className="bg-red-500 sm:mt-10 md:mt-8 sm:ml-5 text-white mt-14 ml-6 px-3 rounded py-1"
        >
          Back
        </button>
        <div className="flex justify-center">
          {locationDetails && (
            <div className="md:flex  sm:ml-0">
              <div>
                <div className="sm:flex sm:items-center">
                  <p className="text-[#074173] text-4xl mt-3 sm:mb-3 font-bold">
                    {locationDetails.location_name},
                  </p>
                  <div className="sm:flex sm:items-center">
                    <p className="sm:mt-3 mt-0 sm:ml-2 font-semibold sm:text-xl text-[#074173] mr-2">
                      {locationDetails.district},
                    </p>
                    <p className="sm:mt-3 mt-0 text-[#074173] font-semibold sm:text-xl">
                      {locationDetails.state}
                    </p>
                  </div>
                </div>
                <img
                  className="sm:h-[400px] sm:rounded sm:w-[650px] h-[200px] rounded w-[330px]"
                  src={locationDetails.image}
                />

                {openingMoreImg && (
                  <div className="bg-black flex flex-col md:justify-center items-center fixed inset-0 bg-opacity-70">
                    <div className=" md:mt-0 sm:w-[500px] mt-60 sm:mt-40 md:w-[820px] w-[320px]  rounded sm:rounded-xl   sm:p-3">
                      <button
                        onClick={() => {
                          setOpeningMoreImg(false);
                        }}
                        className="bg-red-500 ml-[295px] sm:ml-[450px]  md:ml-[772px] px-2  text-white"
                      >
                        X
                      </button>
                      <Slider ref={sliderRef} {...settings}>
                        <img
                          src={locationDetails.image}
                          className="md:h-[450px] sm:h-[350px] h-52"
                        />
                        {locationDetails.additionalImages.map(
                          (addImg, index) => (
                            <img
                              key={index}
                              src={addImg}
                              className="md:h-[450px] sm:h-[350px] h-52"
                            />
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                )}

                <p className="text-[#074173] text-center mb-2 mt-3 font-bold">
                  more photos
                </p>
                <div className="sm:grid w-[260px] overflow-auto h-[100px] sm:ml-0 ml-9 sm:w-auto grid grid-cols-2 gap-3 sm:grid-cols-4">
                  {locationDetails.additionalImages.map((imageUrl) => (
                    <img
                      onClick={() => {
                        setOpeningMoreImg(imageUrl);
                      }}
                      src={imageUrl}
                      className="h-[100px] rounded w-[120px]"
                    />
                  ))}
                </div>
              </div>

              <div className="mt-[20px] sm:mt-[52px] md:ml-10  lg:ml-20">
                <div className="sm:flex">
                  <div className="">
                    <p className="text-[#074173] text-xl font-bold">
                      Location Details
                    </p>
                    <div className="sm:flex mb-2">
                      <div className="">
                        <p className="text-[#074173] font-semibold">
                          Category:
                        </p>
                        <div className="ml-1">
                          {locationDetails.locationCategory.map(
                            (cat, index) => (
                              <li key={index}>{cat}</li>
                            )
                          )}
                        </div>
                      </div>

                      <div className="sm:ml-20 ml-0">
                        <p className="text-[#074173] font-semibold">
                          Suitable For:
                        </p>
                        <div className="ml-1">
                          {locationDetails.suitableFor.map((suit, index) => (
                            <li key={index}>{suit}</li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Staying Facility:
                      </p>
                      <p className="ml-1">
                        {locationDetails.staying_facilities}
                      </p>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Property Type:
                      </p>
                      <p className="ml-1">{locationDetails.location_type}</p>
                    </div>

                    <div className="flex">
                      <p className="text-[#074173] font-semibold">
                        Crowd Allowed:
                      </p>
                      <p className="ml-1 flex items-center">
                        {locationDetails.crowd_allowed}{" "}
                        <MdGroupAdd className="ml-1 text-lg" />
                      </p>
                    </div>
                  </div>
                </div>

                <p className="text-[#074173] mb-3 mt-8 text-xl font-bold">
                  Additional Details
                </p>
                <div className="sm:flex  sm:w-[440px] sm:justify-between">
                  <div>
                    <button
                      className={`${
                        activeSection === "description"
                          ? "bg-[#074173] text-white"
                          : "bg-gray-300  text-[#074173] font-semibold"
                      } rounded px-3 py-1 `}
                      onClick={() => setActiveSection("description")}
                    >
                      Description
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "description" && (
                        <div>
                          <p className="text-[#074173] mb-1 text-xl font-bold">
                            Description
                          </p>
                          <p className="text-justify sm:w-[500px] md:w-[450px] w-[330px] lg:w-[500px]">
                            {locationDetails.location_desciption}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      className={`${
                        activeSection === "publicFacility"
                          ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                          : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                      } rounded px-3 py-1`}
                      onClick={() => setActiveSection("publicFacility")}
                    >
                      Public Facility
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "publicFacility" && (
                        <div>
                          <div className="sm:flex">
                            <div>
                              <p className="text-[#074173] text-lg font-bold">
                                Nearby Airport
                              </p>
                              <div>
                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Name:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.airport_name}
                                  </p>
                                </div>

                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Km:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.airport_km} km
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="md:ml-5 lg:ml-[80px]  ml-0">
                              <p className="text-[#074173] text-lg font-bold">
                                Nearby Railway Station
                              </p>
                              <div>
                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Name:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.station_name}
                                  </p>
                                </div>
                                CFPV8885V{" "}
                                <div className="flex">
                                  <p className="text-[#074173] font-semibold">
                                    Km:
                                  </p>
                                  <p className="ml-1">
                                    {locationDetails.station_km} km
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-2 ">
                            <p className="text-[#074173]  text-lg font-bold">
                              Nearby Bus Stop
                            </p>
                            <div>
                              <div className="flex">
                                <p className="text-[#074173] font-semibold">
                                  Name:
                                </p>
                                <p className="ml-1">
                                  {locationDetails.stop_name}
                                </p>
                              </div>

                              <div className="flex">
                                <p className="text-[#074173] font-semibold">
                                  Km:
                                </p>
                                <p className="ml-1">
                                  {locationDetails.stop_km} km
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      className={`${
                        activeSection === "terms"
                          ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                          : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                      } rounded px-3 py-1`}
                      onClick={() => setActiveSection("terms")}
                    >
                      T&C
                    </button>

                    <div className="sm:hidden">
                      {activeSection === "terms" && (
                        <div>
                          <p className="text-[#074173] mb-1 text-xl font-bold">
                            Terms & Conditions
                          </p>
                          <p className="text-justify">
                            {locationDetails.termsAndCondition.map((terms) => (
                              <div>
                                <li>{terms}</li>
                              </div>
                            ))}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    className={`${
                      activeSection === "remarks"
                        ? "bg-[#074173] sm:mt-0 mt-2 text-white"
                        : "bg-gray-300 sm:mt-0 mt-2 text-[#074173] font-semibold"
                    } rounded px-3 py-1 `}
                    onClick={() => setActiveSection("remarks")}
                  >
                    Remarks
                  </button>
                </div>

                <div className="mt-3">
                  {activeSection === "description" && (
                    <div className="sm:block hidden">
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Description
                      </p>
                      <p className="text-justify sm:w-[500px] md:w-[420px] w-[330px] lg:w-[500px]">
                        {locationDetails.location_desciption}
                      </p>
                    </div>
                  )}
                  {activeSection === "publicFacility" && (
                    <div className="sm:block hidden">
                      <div className="sm:flex">
                        <div>
                          <p className="text-[#074173] text-lg font-bold">
                            Nearby Airport
                          </p>
                          <div>
                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Name:
                              </p>
                              <p className="ml-1">
                                {locationDetails.airport_name}
                              </p>
                            </div>

                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Km:
                              </p>
                              <p className="ml-1">
                                {locationDetails.airport_km} km
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="md:ml-5 lg:ml-[80px]  ml-0">
                          <p className="text-[#074173] text-lg font-bold">
                            Nearby Railway Station
                          </p>
                          <div>
                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Name:
                              </p>
                              <p className="ml-1">
                                {locationDetails.station_name}
                              </p>
                            </div>

                            <div className="flex">
                              <p className="text-[#074173] font-semibold">
                                Km:
                              </p>
                              <p className="ml-1">
                                {locationDetails.station_km} km
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 ">
                        <p className="text-[#074173]  text-lg font-bold">
                          Nearby Bus Stop
                        </p>
                        <div>
                          <div className="flex">
                            <p className="text-[#074173] font-semibold">
                              Name:
                            </p>
                            <p className="ml-1">{locationDetails.stop_name}</p>
                          </div>

                          <div className="flex">
                            <p className="text-[#074173] font-semibold">Km:</p>
                            <p className="ml-1">{locationDetails.stop_km} km</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeSection === "terms" && (
                    <div className="hidden sm:block">
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Terms & Conditions
                      </p>
                      <p className="text-justify">
                        {locationDetails.termsAndCondition.map((terms) => (
                          <div>
                            <li>{terms}</li>
                          </div>
                        ))}
                      </p>
                    </div>
                  )}
                  {activeSection === "remarks" && (
                    <div>
                      <p className="text-[#074173] mb-1 text-xl font-bold">
                        Remarks
                      </p>
                      <p className="text-justify">
                        {locationDetails.remarks.map((rem) => (
                          <div>
                            <li>{rem}</li>
                          </div>
                        ))}
                      </p>
                    </div>
                  )}
                </div>

                <div className="">
                  <div className="flex justify-between mt-3 w-[320px] sm:w-[220px]">
                    <button
                      onClick={() => {
                        poppingUp();
                      }}
                      className="bg-[#074173] rounded px-3 py-1 text-white mt-3"
                    >
                      Enquiry
                    </button>

                    {openingClosing && (
                      <Enquiry
                        locationId={locationDetails.id}
                        closing={setOpeningClosing}
                      />
                    )}

                    <a
                      href="https://wa.me/+919998973834"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-[#074173] rounded px-3 py-1 text-white mt-3"
                    >
                      <div className="flex items-center">
                        WhatsApp
                        <SiWhatsapp className="ml-1" />
                      </div>
                    </a>

                    <a
                      href="tel:9998973834"
                      className="bg-[#074173] block sm:hidden rounded px-3 py-1 text-white mt-3"
                    >
                      <div className="flex items-center">
                        Call Now
                        <FaPhoneFlip className="ml-1" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default DetailedLocation;
