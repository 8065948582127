import { Form, Formik } from "formik";
import React from "react";
import FormikInput from "./FormikInput";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";

function ResetPassword({ closing }) {
  function ResettingPassword(email) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("an email has been sent...");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error("try again");
      });
  }

  return (
    <div className="bg-black flex flex-col justify-center items-center fixed inset-0 bg-opacity-70">
      <div>
        <div className="bg-white sm:ml-0 sm:mr-0 ml-5 mr-5 rounded p-2">
          <div className="flex  items-center justify-between">
            <p className="text-2xl ml-12 sm:ml-[125px] font-bold text-[#074173]">
              Reset Password
            </p>
            <button
              onClick={() => {
                closing(false);
              }}
              className="bg-red-500 text-white px-2  rounded"
            >
              X
            </button>
          </div>

          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, { setSubmitting }) => {
              ResettingPassword(values.email);
              setSubmitting(false);
            }}
          >
            <Form>
              <div className="flex mt-2 items-center">
                <FormikInput name={"email"} placeholder={"Registered email"} />
                <button className="bg-[#074173] text-white mt-2 py-[7px] rounded px-3 ml-3">
                  Send
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
