import React, { useState, useEffect, useRef } from "react";
import UserExploreLocations from "./UserExploreLocations";
import Footer from "./Footer";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import img1 from "../Components/dark theme logo (2).png";
import img2 from "../Components/Location Dekho (1).png";
import img3 from "../Components/dark theme logo (2).png";
import img5 from "../Components/image1.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import img6 from "../Components/displayImage.jpg";
import img7 from "../Components/displayImage3.jpg";
import img8 from "../Components/displayImage2.jpg";

function UserSection() {
  const [shrinkingFilters, setShrinkingFilters] = useState(false);
  const [activeSection, setActiveSection] = useState("homeNav");
  const navigating = useNavigate();
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 50,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: false,
    fade: true,
    cssEase: "linear",
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition < 540) {
      setActiveSection("homeNav");
    } else if (scrollPosition >= 540 && scrollPosition < 1200) {
      setActiveSection("user-explore-locations");
    } else {
      setActiveSection("general-enquiry");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="">
      <div
        id="homeNav"
        className="relative sm:h-[250px] md:h-[540px] h-40 w-full"
      >
        <Slider
          ref={sliderRef}
          {...settings}
          className="absolute top-0 left-0 w-full h-full"
        >
          <div>
            <img
              src={img5}
              className="w-full sm:h-[250px] md:h-[540px] h-40 object-cover"
              alt="Slide 1"
            />
          </div>
          <div>
            <img
              src={img6}
              className="w-full sm:h-[250px] md:h-[540px] h-40 object-cover"
              alt="Slide 2"
            />
          </div>
          <div>
            <img
              src={img7}
              className="w-full sm:h-[250px] md:h-[540px] h-40 object-cover"
              alt="Slide 3"
            />
          </div>
          <div>
            <img
              src={img8}
              className="w-full sm:h-[250px] md:h-[540px] h-40 object-cover"
              alt="Slide 4"
            />
          </div>
        </Slider>

        <div className="fixed top-0 z-50 w-full sm:flex">
          <div className="flex bg-white p-1 justify-between items-center">
            <img
              className="h-[35px] block sm:hidden rounded w-[125px]"
              src={img3}
              alt="Logo"
            />
            <button
              className={`sm:hidden rounded text-white bg-[#074173] p-1`}
              onClick={() => setShrinkingFilters(!shrinkingFilters)}
            >
              <FaBars size={15} />
            </button>
          </div>

          <div
            className={`${
              shrinkingFilters ? "block" : "hidden"
            } sm:flex sm:items-center sm:justify-between z-50 sm:mt-0 fixed top-0 bg-[#074173] w-full text-center p-2 sm:py-2 sm:px-5 text-white sm:text-[#074173] sm:bg-white shadow font-[600] sm:text-xl sm:w-full text-base`}
          >
            <div>
              <button
                onClick={() => setShrinkingFilters(!shrinkingFilters)}
                className="bg-white sm:hidden z-50 block rounded text-[#074173]"
              >
                <FaTimes size={24} />
              </button>
              <img
                className="h-[50px] hidden sm:block rounded w-[200px]"
                src={img1}
                alt="Logo"
              />
              <img
                className="h-[50px] block sm:hidden rounded ml-10 w-[230px]"
                src={img2}
                alt="Logo"
              />
            </div>
            <div className="sm:flex sm:items-center sm:w-[350px] md:w-[400px] sm:justify-between">
              <p>
                <Link
                  to="homeNav"
                  smooth={true}
                  duration={700}
                  className={
                    activeSection === "homeNav"
                      ? "border-b-2 border-white sm:border-[#074173]"
                      : ""
                  }
                >
                  Home
                </Link>
              </p>

              <p
                onClick={() => {
                  navigating("/AboutUs");
                }}
              >
                About Us
              </p>

              <p>
                <Link
                  to="user-explore-locations"
                  smooth={true}
                  duration={700}
                  className={
                    activeSection === "user-explore-locations"
                      ? "border-b-2 border-white sm:border-[#074173]"
                      : ""
                  }
                >
                  Locations
                </Link>
              </p>
              <p
                onClick={() => {
                  navigating("/ContactUs");
                }}
              >
                Contact Us
              </p>
            </div>
          </div>
        </div>

        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="flex items-center font-[650]">
            <p className="text-white text-2xl sm:text-6xl md:text-8xl mr-4">
              Reel Life,
            </p>
            <p className="text-white text-2xl sm:text-6xl md:text-8xl">
              Real Places.
            </p>
          </div>
        </div>
      </div>

      <div id="user-explore-locations">
        <UserExploreLocations />
      </div>

      <div className="mt-20" id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default UserSection;
